<script>
var address_id = location.host;
</script>
<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <!-- Bookmarks Container -->
      <!-- <bookmarks /> -->
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <div
        v-if="isAdmin && addresse_ip == 'localhost:8081'"
        style="margin-right: 15px"
      >
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
          @click="onSubmit"
        >
          <feather-icon icon="RefreshCcwIcon" class="mr-50" />
          <span class="align-middle">{{ $t("Synchronize") }}</span>
        </b-button>
      </div>
      <locale />
      <dark-Toggler class="d-none d-lg-block" />
      <notification-dropdown />
      <user-dropdown />
      <b-overlay :show="busy" no-wrap fixed>
        <template v-slot:overlay>
          <div
            v-if="processing"
            class="text-center p-4 bg-primary text-light rounded"
          >
            <b-icon icon="cloud-upload" font-scale="4"></b-icon>
            <div class="mb-3">{{ $t("Processing") }}</div>
          </div>
          <div
            v-else
            ref="dialog"
            tabindex="-1"
            role="dialog"
            aria-modal="false"
            aria-labelledby="form-confirm-label"
            class="text-center p-3"
          >
            <p>
              <strong id="form-confirm-label">{{ $t("Are you sure?") }}</strong>
            </p>
            <div class="d-flex">
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-danger"
                class="mr-3"
                @click="onCancel"
              >
                {{ $t("Cancel") }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="outline-success"
                @click="onOK"
              >
                {{ $t("OK") }}
              </b-button>
            </div>
          </div>
        </template>
      </b-overlay>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BButton,
  BOverlay,
  BForm,
  BProgress,
  BIcon,
  BFormGroup,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BFormFile,
} from "bootstrap-vue";
import Bookmarks from "./components/Bookmarks.vue";
import Locale from "./components/Locale.vue";
import SearchBar from "./components/SearchBar.vue";
import DarkToggler from "./components/DarkToggler.vue";
import CartDropdown from "./components/CartDropdown.vue";
import NotificationDropdown from "./components/NotificationDropdown.vue";
import UserDropdown from "./components/UserDropdown.vue";
import Ripple from "vue-ripple-directive";
import instance from "@/libs/axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

var address_id = location.host;
export default {
  components: {
    BLink,

    // Navbar Components
    BNavbarNav,
    Bookmarks,
    Locale,
    SearchBar,
    DarkToggler,
    CartDropdown,
    NotificationDropdown,
    UserDropdown,
    BButton,
    BOverlay,
    BForm,
    BProgress,
    BIcon,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BFormFile,
  },
  directives: {
    Ripple,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },

  data: () => ({
    busy: false,
    processing: false,
    isAdmin: null,
    addresse_ip: address_id,
  }),

  created() {
    const userData = JSON.parse(localStorage.getItem("userData"));
    this.isAdmin = userData?.role === "admin";
  },

  methods: {
    onSubmit() {
      this.processing = false;
      this.busy = true;
    },
    onCancel() {
      this.busy = false;
    },
    async onOK() {
      try {
        this.counter = 1;
        this.processing = true;

        await instance.post("/parameters/sync-databases/");
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: this.$t("Success"),
            text: this.$t("Synchronizing has been successful"),
            icon: "CheckIcon",
            variant: "success",
            noAutoHide: true,
          },
        });
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title:
              err.message ?? this.$t("An error has occurred. Please try again"),
            icon: "AlertCircle",
            variant: "danger",
            noAutoHide: true,
          },
        });
      } finally {
        this.busy = this.processing = false;
      }
    },
  },
};
</script>
